































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Schedules, Props, User, Global, Errors } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';
import CurveChart from './CurveChart.vue';
import API, { Types } from '@/modules/API';
import PropUtils from '@/modules/PropUtils';
import Utils from '@/modules/Utils';
import { Curve } from '@/modules/Types';
import moment from 'moment';
import * as sunCalc from 'suncalc';
import cloneDeep from 'lodash/cloneDeep';
import PropEditor from '@/components/PropEditor.vue';

@Component({
  components: {
    CurveChart,
    PropEditor
  }
})
export default class NewCurve extends Vue {
  @Global.State('lang') lang;
  @Global.State('timezone') projectTimezone;
  @Global.State('dusk_dawn_data') dusk_dawn_data;
  @Errors.State('error') error;
  @Schedules.Action('changeCurve') changeCurve;
  @Schedules.Action('runSchedulerInServer') runSchedulerInServer;
  @Schedules.State('userCurves') userCurves;
  @User.State('project') project;
  @Props.State('list') projectProperties;
  
  @Prop() dataCurve;
  @Prop() dialog;
  @Prop() setCurveClicked;
  @Prop() setActiveCurveData;
  @Prop() updateSelctedCurves;
  @Prop() curves_map;
  @Prop() preview;
  @Prop() setPreview;

  colors = ['blue', 'red', 'yellow', 'green', 'purple', 'black', 'grey', 'pink', '#22A699', '#E893CF', '#4942E4', '#99627A', '#3C486B', '#EA906C', '#B3C890'];
  name = '';
  description = '';
  timezone = '';
  originalTimezoneDatasets = [];
  offset = 0;
  duskDawnProperty = null;
  duskPowerLevel = 100;
  dawnPowerLevel = 100;
  duskOffset = 0;
  dawnOffset = 0;
  loading = true;
  minDuskIndex = null;
  minDawnIndex = null;
  maxDuskIndex = null;
  maxDawnIndex = null;
  startDuskOffset = null;
  startDawnOffset = null;
  endDuskOffset = null;
  endDawnOffset = null;
  duskSteps = 0;
  dawnSteps = 0;
  showDuskDawnOptions = false;
  originalStart = '';
  serverUtcDatasets = [];
  cleanUtcDatasets = [];
  minUtcDusk = null;
  maxUtcDusk = null;
  minUtcDawn = null;
  maxUtcDawn = null;
  turnOn = '';
  turnOff = '';
  duskDawnWindowColor: '#DCDCDC';
  sunriseTimeStart = '';
  duskStart = '';
  sunsetTimeStart = '';
  dawnStart = '';

  curve: Curve = {
    id: '',
    name: '',
    description: '',
    start: {
      type: 'none',
      offset: 0,
      duration: '0',
      power: 100
    },
    end: {
      type: 'none',
      offset : 0,
      duration: '0',
      power: 100
    },
    color: 'blue',
    datasets: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 70, 70, 70, 70, 70, 70, 70, 70, 70, 70, 70, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    labels: [],
    timezone: ''
  };

  created() {
    this.setDuskDawnProperty();
    this.initializeDatasets();
    this.calculateMinMaxDuskDawn(true);
    this.$nextTick(() => {
      this.updateChartTimes();
    });
  }

  mounted(){
    if (!this.preview) {
      this.$refs.curve_name['focus']();
    }
  }

  setDuskDawnProperty(){
    this.duskDawnProperty = PropUtils.getProperty(this.projectProperties, 'project.dusk_dawn_min_max_yearly');
    this.duskDawnProperty = this.duskDawnProperty ? this.duskDawnProperty.value : null;
    if (!this.duskDawnProperty){
      const message = 'There is no dusk dawn property in this project. Please contact with support team.';
      Utils.showNotify('error', 'Error!', message);
    }
  }

  initializeDatasets(){
    if (this.dataCurve && Object.keys(this.dataCurve).length > 0)
      this.curve = cloneDeep({...this.dataCurve});

    this.originalStart = this.curve.start.type;
    this.serverUtcDatasets = [...this.curve.datasets]; // for saving the original curve from server
    this.cleanUtcDatasets = [...this.curve.datasets]; // for cleaning the offset 
  }

  updateChartTimes(){
    this.offset = this.getTimezoneOffset(); 
    this.calculateSunData();
    this.calculateMinMaxDuskDawn(false); // calculate according to timezone offset
    this.setCleanUtcDatasets();
    this.updateDuskDawnByWindowOffset(); // calculate according to windows offset

    this.originalTimezoneDatasets = this.dataCurve && Object.keys(this.dataCurve).length > 0
      ? this.getDatasetByOffset(this.serverUtcDatasets, false)
      : [...this.curve.datasets];
    this.setCurveToDashboard();
    if (this.curve.start.type === 'dusk') this.showDuskDawnOptions = true;
  }

  updateDuskDawnByWindowOffset(){
    if (this.duskDawnProperty){
      this.setPowerAndOffset();
      this.updateDuskOffset();
      this.updateDawnOffset();
    }
  }

  calculateSunData() {
    try {
      this.setDuskDawnByTimezone();
    } catch (error) {
      console.log('Error while converting Sun Data:', error);
      this.clearSunData();
    }
  }

  setDuskDawnByTimezone(){
    this.sunriseTimeStart = this.dusk_dawn_data.sunrise.format('hh:mm A');
    this.sunsetTimeStart = this.dusk_dawn_data.sunset_start.format('hh:mm A');
    this.duskStart = this.dusk_dawn_data.dusk.format('hh:mm A');
    this.dawnStart = this.dusk_dawn_data.dawn.format('hh:mm A');
    this.turnOn = Utils.convertTimestamp(this.dusk_dawn_data.dusk_timestamp, 'hh:mm A', this.projectTimezone);
    this.turnOff = Utils.convertTimestamp(this.dusk_dawn_data.dawn_timestamp, 'hh:mm A', this.projectTimezone);
  }

  clearSunData(){
    this.sunriseTimeStart = '';
    this.duskStart = '';
    this.sunsetTimeStart = '';
    this.dawnStart = '';
  }

  // initializeTimezoneNames(){
  //   momentZ.tz.load({
  //     zones: [],
  //     links: [],
  //     version: '2019c'
  //   });
  //   this.timezoneNames = momentZ.tz.names();
  //   this.timezoneNames = this.timezoneNames.filter(
  //     (e) =>
  //       !e.includes('GMT') &&
  //       !e.includes('UTC') &&
  //       !e.includes('Universal') &&
  //       !e.includes('ROK') &&
  //       !e.includes('ROC') &&
  //       !e.includes('UCT') &&
  //       !e.includes('W-SU') &&
  //       !e.includes('WET') &&
  //       !e.includes('Zulu') &&
  //       !e.includes('MST') &&
  //       !e.includes('MET') &&
  //       !e.includes('NZ') &&
  //       !e.includes('PRC') &&
  //       !e.includes('PST') 
  //     );
  // }

  setCurveToDashboard() {
    if (this.originalStart === 'dusk') {
      if (this.duskDawnProperty) this.removeServerDuskDawnSign(); // remove dusk(-1) dawn(-2) server sign from timezone datasets
      this.showDuskDawnOptions = true;
    }else {
      this.showDuskDawnOptions = false;
    }
    this.loading = false;
  }

  setCleanUtcDatasets(){
    if (this.duskDawnProperty){
      this.setWindowsLocationByOffset(this.cleanUtcDatasets);
      this.unsignDuskDawnWindow(this.cleanUtcDatasets, this.minUtcDusk, this.maxUtcDusk, this.minUtcDawn, this.maxUtcDawn, this.duskPowerLevel, this.dawnPowerLevel);
      this.movePreviousValues(); 
    }
  }

  removeServerDuskDawnSign(){
    this.setWindowsLocationByOffset(this.originalTimezoneDatasets);
    this.unsignDuskDawnWindow(this.originalTimezoneDatasets, this.startDuskOffset, this.endDuskOffset, this.startDawnOffset, this.endDawnOffset, this.duskPowerLevel, this.dawnPowerLevel);
  }

  movePreviousValues(){
    let startCopy, endCopy, datasets;
    const startBeforeDusk = this.calculateIndexInRange(this.minDuskIndex - 5);
    const endBeforeDusk = this.minDuskIndex;

    if (this.duskOffset !== 0){
      startCopy = this.calculateIndexInRange(this.startDuskOffset - 5);
      endCopy = this.startDuskOffset;
      datasets = this.getDatasetsToCopy(this.cleanUtcDatasets, startCopy, endCopy);
      this.copyDatasetsValues(this.cleanUtcDatasets, startBeforeDusk, endBeforeDusk, datasets);
    }
    const startAfterDawn = this.calculateIndexInRange(this.maxDawnIndex);
    const endAfterDawn = this.calculateIndexInRange(this.maxDawnIndex + 5);

    if (this.dawnOffset !== 0){
      startCopy = this.calculateIndexInRange(this.endDawnOffset);
      endCopy = this.calculateIndexInRange(this.endDawnOffset + 5);
      datasets = this.getDatasetsToCopy(this.cleanUtcDatasets, startCopy, endCopy);
      this.copyDatasetsValues(this.cleanUtcDatasets, startAfterDawn, endAfterDawn, datasets);
    }
    this.signWindow(this.cleanUtcDatasets, endAfterDawn, startBeforeDusk, 0);
  }

  getDatasetsToCopy(datasets, startCopy, endCopy){
    return startCopy > endCopy 
      ? datasets.slice(startCopy, 48).concat(datasets.slice(0, endCopy))
      : datasets.slice(startCopy, endCopy);
  }

  setPowerAndOffset(){
    this.duskPowerLevel = +this.curve.start.power;
    this.duskOffset = +this.curve.start.offset;
    this.dawnPowerLevel = +this.curve.end.power;
    this.dawnOffset = +this.curve.end.offset;
  }

  getDatasetByOffset(datasets, toUTC = false) {
    let moveToLeft = this.offset < 0;
    const steps = Math.abs(this.offset) * 2; // need to move 2 steps for getting one hour
    if (toUTC) moveToLeft = !moveToLeft;
    return this.moveDatasetTo(datasets, steps, moveToLeft);
  }

  getTimezoneOffset() {
    try {
      const new_offset = moment().tz(this.projectTimezone).utcOffset();
      const remain = Math.abs(new_offset) % 60;
      let roundedRemain = remain >= 16 && remain <= 45 ? 0.5 : 0; 
      roundedRemain = new_offset < 0 ? -roundedRemain : roundedRemain;
      let offsetInHours = new_offset < 0 ? Math.ceil(new_offset / 60) : Math.floor(new_offset / 60);
      offsetInHours = remain >= 46 && remain <= 59 ? offsetInHours > 0 ? offsetInHours + 1 : offsetInHours - 1 : offsetInHours;
      return offsetInHours + roundedRemain;
    } catch (error) {
      console.log('error, while calculate offset', error);
      return 0;
    }
  }
  
  moveDatasetTo(datasets, offset, moveToLeft) {
    return moveToLeft 
      ? this.moveToLeft(datasets, offset) 
      : this.moveToRight(datasets, offset);
  }

  moveToLeft(datasets, offset){
    return datasets.slice(offset).concat(datasets.slice(0, offset));
  }

  moveToRight(datasets, offset){
    const start = datasets.length - offset;
    return datasets.slice(start, datasets.length).concat(datasets.slice(0, start));
  }

  calculateMinMaxDuskDawn(firstInit){
    if (this.duskDawnProperty){
      const minDusk = this.duskDawnProperty['min dusk'];
      const maxDusk = this.duskDawnProperty['max dusk'];
      const minDawn = this.duskDawnProperty['min dawn'];
      const maxDawn = this.duskDawnProperty['max dawn'];

      this.calculateMinIndex(minDusk, 'dusk', firstInit);
      this.calculateMinIndex(minDawn, 'dawn', firstInit);
      this.calculateMaxIndex(maxDusk, 'dusk', firstInit);
      this.calculateMaxIndex(maxDawn, 'dawn', firstInit);
    }
  }

  calculateMinIndex(time, type, firstInit) {
    let [hour, minutes] = time.split(':');
    hour = `${+hour + this.offset}`;
    minutes = minutes > 30 ? '30' : '00';
    const decimalHour = this.timeToDecimal(hour, minutes);
    const minIndex = decimalHour > 12 ? (decimalHour - 12) * 2 : (decimalHour + 12) * 2;
    firstInit 
      ? type === 'dusk' ? this.minUtcDusk = minIndex : this.minUtcDawn = minIndex
      : type === 'dusk' ? this.minDuskIndex = minIndex : this.minDawnIndex = minIndex;
  }

  calculateMaxIndex(time, type, firstInit) {
    let [hour, minutes] = time.split(':');

    hour = minutes > 30 ? `${+hour + 1}` : hour;
    hour = `${+hour + this.offset}`;
    minutes = minutes > '00' && minutes <= '30' ? '30' : '00';

    const decimalHour = this.timeToDecimal(hour, minutes);
    const maxIndex = decimalHour > 12 ? (decimalHour - 12) * 2 : (decimalHour + 12) * 2;
    firstInit 
      ? type === 'dusk' ? this.maxUtcDusk = maxIndex : this.maxUtcDawn = maxIndex
      : type === 'dusk' ? this.maxDuskIndex = maxIndex : this.maxDawnIndex = maxIndex;
  }

  timeToDecimal(hour, minutes) {
    return minutes === '30' ? +hour + 0.5 : +hour;
  }

  updateEndType(type){
    this.curve.end.type = type;
    this.showDuskDawnOptions = type === 'dawn';
  }

  updateStartType(type){
    this.curve.start.type = type;
    this.showDuskDawnOptions = type === 'dusk';
  }

  save() {
    if (this.$refs.curve_name['validate']()) {
      const finalCurve = this.setCurveToServer();
      this.changeCurve(finalCurve);
      this.saveCurveProperty(finalCurve);
    }
  }

  isCurveNameExists(){
    return this.userCurves.some((curve) => curve.name === this.curve.name && curve.id !== this.curve.id);
  }

  close(){
    this.setCurveClicked(false);
  }

  convertPowerAndOffsetToString(curve){
    curve.start.power = this.curve.start.type === 'dusk' && this.duskDawnProperty ? this.duskPowerLevel.toString() : '100';
    curve.end.power = this.curve.start.type === 'dusk' && this.duskDawnProperty ? this.dawnPowerLevel.toString() : '100';
    curve.start.offset = this.curve.start.type === 'dusk' && this.duskDawnProperty ? this.duskOffset.toString() : '0';
    curve.end.offset = this.curve.start.type === 'dusk' && this.duskDawnProperty ? this.dawnOffset.toString() : '0';
  }

  setCurveToServer(){
    const finalCurve = cloneDeep({...this.curve});
    this.convertPowerAndOffsetToString(finalCurve);
    if (!this.dataCurve) finalCurve.id = '_' + Math.random().toString(36).substr(2, 9);
    const finalDatasets = this.$refs.curveChart['getDatasets']();

    if (this.curve.start.type === 'dusk'){
      if (this.duskDawnProperty){
        this.signDuskDawnWindow(finalDatasets, this.startDuskOffset, this.endDuskOffset, this.startDawnOffset, this.endDawnOffset);
      }else{
        finalCurve.start.type = 'none';
        finalCurve.end.type = 'none';
      }
    }
    finalCurve.datasets = this.getDatasetByOffset(finalDatasets, true); // convert timezone to utc
    return finalCurve;
  }

  signDuskDawnWindow(datasets, startDusk, endDusk, startDawn, endDawn){
    this.signWindow(datasets, startDusk, endDusk, -1);
    this.signWindow(datasets, startDawn, endDawn, -2);
  }

  setWindowsLocationByOffset(datasets){
    this.setDuskDawnLocationByOffset(datasets, -1);
    this.setDuskDawnLocationByOffset(datasets, -2);
  }

  unsignDuskDawnWindow(datasets, startDusk, endDusk, startDawn, endDawn, duskPower, dawnPower){
    this.signWindow(datasets, startDusk, endDusk, duskPower); // sign window with original dusk dawn offset
    this.signWindow(datasets, startDawn, endDawn, dawnPower); // sign window with original dusk dawn offset
  }

  signWindow(datasets, start, end, value){
    if (start > end){
      for (let i = start; i < 48; i++)
        datasets[i] = value;
      for (let i = 0; i < end; i++)
        datasets[i] = value;
    }else{
      for (let i = start; i < end; i++)
        datasets[i] = value;
    }
  }

  copyDatasetsValues(datasets, start, end, datasetsToCopy){
    let index = 0, i = 0;
    if (datasets.length !== 0){
      if (start > end){
        for (i = start; i < 48; i++){
          datasets[i] = datasetsToCopy[index];
          index++;
        }
        for (i = 0; i < end; i++){
          datasets[i] = datasetsToCopy[index];
          index++;
        }
      }else{
        for (i = start; i < end; i++){
          datasets[i] = datasetsToCopy[index];
          index++;
        }
      }
    }
  }

  setDuskDawnLocationByOffset(datasets, value){
    const [start, end] = this.getStartEndIndex(datasets, value);
    value === -1 ? this.startDuskOffset = start : this.startDawnOffset = start;
    value === -1 ? this.endDuskOffset = end + 1 : this.endDawnOffset = end + 1;
  }

  getStartEndIndex(datasets, value){
    return [datasets.indexOf(value), datasets.lastIndexOf(value)];
  }

  @Watch('duskOffset')
  onDuskOffsetChanged(){
    if (this.duskDawnProperty) {
      this.updateDuskOffset();
      this.turnOn = this.duskOffset >= 0 
        ? Utils.addToTimestamp_string(this.dusk_dawn_data.dusk_timestamp, 'minutes', this.duskOffset, 'hh:mm A', this.projectTimezone)
        : Utils.removeFromTimestamp_string(this.dusk_dawn_data.dusk_timestamp, 'minutes', Math.abs(this.duskOffset), 'hh:mm A', this.projectTimezone);
    }
  }

  updateDuskOffset(){
    this.duskSteps = this.calculateWindowOffset(this.duskOffset);
    this.startDuskOffset = this.calculateIndexInRange(this.minDuskIndex + this.duskSteps);
    this.endDuskOffset = this.calculateIndexInRange(this.maxDuskIndex + this.duskSteps);
  }

  @Watch('dawnOffset')
  onDawnOffsetChanged(){
    if (this.duskDawnProperty){
      this.updateDawnOffset();
      this.turnOff = this.dawnOffset >= 0
        ? Utils.addToTimestamp_string(this.dusk_dawn_data.dawn_timestamp, 'minutes', this.dawnOffset, 'hh:mm A', this.projectTimezone)
        : Utils.removeFromTimestamp_string(this.dusk_dawn_data.dawn_timestamp, 'minutes', Math.abs(this.dawnOffset), 'hh:mm A', this.projectTimezone);
    } 
  }

  updateDawnOffset(){
    this.dawnSteps = this.calculateWindowOffset(this.dawnOffset);
    this.startDawnOffset = this.calculateIndexInRange(this.minDawnIndex + this.dawnSteps);
    this.endDawnOffset = this.calculateIndexInRange(this.maxDawnIndex + this.dawnSteps);
  }

  calculateWindowOffset(offset){
    const positiveOffset = Math.abs(offset);
    const remain = positiveOffset % 60;
    let hours = Math.floor(positiveOffset / 60);
    hours = remain > 30 ? hours + 1 : hours;
    const roundedMinutes = remain > 30 || remain === 0 ? 0 : 0.5;
    const roundedOffsetInHours = (hours + roundedMinutes) * 2;
    return offset < 0 ? -roundedOffsetInHours : roundedOffsetInHours;
  }

  saveCurveProperty(finalCurve) {
    (this.$refs.propEditor as PropEditor).save(this.userCurves, {
      name: 'dashboard.curves',
      objectID: this.project.id,
      objectType: Types.PROJECTS
    }, () => { 
      this.close(); 
      if (this.dataCurve) this.runSchedulerInServer();
      this.updateSelctedCurves([this.curves_map.get(finalCurve.id)]);
      this.setActiveCurveData({id: finalCurve.id, color: finalCurve.color});
    }, 'Curve Updated Successfully');

  }

  setColor(color) {
    this.curve.color = color;
  }

  calculateIndexInRange(index){
    return index < 0 ? 48 + index : index >= 48 ? index - 48 : index;
  }
}
