<template>
  <div>
    <div class="asd"></div>
    <BarChart
      :chartData="datacollection"
      :options="options"
      ref="barChart"
      :styles="{height: '500px', position: 'relative', width: '100%'}"
    />
    <MessageDialog :dialog="isMessageDialogOpen" :message="message" @dialogClosed="isMessageDialogOpen=false"/>
  </div>
</template>

<script>
  import BarChart from './BarChart.js';
  import MessageDialog from '@/components/MessageDialog.vue';
  import 'chartjs-plugin-dragdata';
  import cloneDeep from 'lodash/cloneDeep';

  export default {
    name: 'CurveChart',
    props: {
      preview: {
        type: Boolean,
        default: () => false
      },
      setPreview: {
        type: Function
      },
      originalTimezoneDatasets: {
        type: Array
      },
      timezone: {
        type: String,
        default: () => ''
      },
      duskDawnProperty: {
        type: Object
      },
      minDuskIndex: {
        type: Number
      },
      minDawnIndex: {
        type: Number
      },
      maxDuskIndex: {
        type: Number
      },
      maxDawnIndex: {
        type: Number
      },
      startDuskOffset: {
        type: Number
      },
      startDawnOffset: {
        type: Number
      },
      endDuskOffset: {
        type: Number
      },
      endDawnOffset: {
        type: Number
      },
      duskSteps: {
        type: Number
      },
      dawnSteps: {
        type: Number
      },
      originalStart: {
        type: String
      },
      startType: {
        type: String
      },
      endType: {
        type: String
      },
      duskPowerLevel: {
        type: Number
      },
      dawnPowerLevel: {
        type: Number
      }
    },
    components: {
      BarChart,
      MessageDialog
    },
    data() {
      const vm = this;
      return {
        isMessageDialogOpen: false,
        message: '',
        dragPrevFluctuations: -1,
        previousDragDimming: 0,
        currentFluctuations: -1,
        onClickDimming: -1,
        duskDawnWindowColor: '#DCDCDC',
        duskDawnChartColor: '#FFA07A',
        defaultChartColor: '#ffeb3b',
        borderColor: '#FFFFFF',
        datacollection: {
          labels: ['12:00 PM', '12:30 PM', '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM', '4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM', '6:00 PM', '6:30 PM', '7:00 PM', '7:30 PM', '8:00 PM', '8:30 PM', '9:00 PM', '9:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM',
            '12:00 AM', '12:30 AM', '1:00 AM', '1:30 AM', '2:00 AM', '2:30 AM', '3:00 AM', '3:30 AM', '4:00 AM', '4:30 AM', '5:00 AM', '5:30 AM', '6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM', '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM']
        },
        options: {
          showLines: true,
          animation: false,
          dragData: true,
          scales: {
            xAxes: [{
              ticks: {
                autoSkip: false,
                minRotation: 50,
                fontSize: 11
              }
            }],
            yAxes: [
              {
                ticks: {
                  min: 0,
                  max: 100,
                  stepSize: 10
                },
                scaleLabel: {
                  display: true,
                  labelString: this.$t('Dim Level')
                }
              }
            ]
          },
          offset: true,
          layout: {
            padding: 5
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 0,
          legend: {
            display: false
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem) => {
                return vm.duskDawnProperty
                  ? vm.startType === 'dusk' && tooltipItem.index >= vm.startDuskOffset && tooltipItem.index < vm.endDuskOffset 
                  ? ` Dusk Power: ${vm.duskPowerLevel}`
                  : vm.endType === 'dawn' && tooltipItem.index >= vm.startDawnOffset && tooltipItem.index < vm.endDawnOffset
                  ? ` Dawn Power: ${vm.dawnPowerLevel}`
                  : tooltipItem.yLabel
                  : tooltipItem.yLabel;
              }
            }
          },
          dragDataRound: 0,
          onDragStart(e, element) {
            vm.previousDragDimming = vm.datacollection.datasets[0].data[element._index];
            vm.dragPrevFluctuations = vm.checkNumOfCurves(vm.datacollection.datasets[0].data);
            vm.onClickDimming = -1;
            vm.currentFluctuations = -1;
            if (vm.startType === 'dusk' && vm.duskDawnProperty && ((element._index >= vm.startDuskOffset && element._index < vm.endDuskOffset) ||
                (element._index >= vm.startDawnOffset && element._index < vm.endDawnOffset))) {
              return false;
            }
          },
          onDragEnd(e, datasetIndex, index, value) {
            setTimeout(() => {
              const current_fluctuations = vm.currentFluctuations > -1 
                ? vm.currentFluctuations 
                : vm.checkNumOfCurves(vm.datacollection.datasets[0].data);
              const dimming = vm.onClickDimming > -1 ? vm.onClickDimming : value;
              if (dimming > 0 && current_fluctuations > 8 && vm.dragPrevFluctuations < current_fluctuations){
                vm.datacollection.datasets[0].data[index] = vm.previousDragDimming;
                vm.message = 'You have reached the maximum number of dimming fluctuations in the curve, to add a fluctuation you must delete one';
                vm.isMessageDialogOpen = true;
                vm.$refs.barChart.renderChart(vm.datacollection, vm.options);
                vm.dragPrevFluctuations = -1;
                return false;
              }
              vm.dragPrevFluctuations = -1;
            }, 100);
          },
          onClick(event) {
            let index = null, valueY = null;
            for (const scaleName in this.scales) {
              const scale = this.scales[scaleName];
              if (scale.isHorizontal()) {
                index = scale.getValueForPixel(event.offsetX);
                if (vm.duskDawnProperty){
                  if ((vm.startType === 'dusk' && index >= vm.startDuskOffset && index < vm.endDuskOffset) ||
                    (vm.endType === 'dawn' && index >= vm.startDawnOffset && index < vm.endDawnOffset)) {
                      vm.message = 'You can control dimming level of dusk and dawn in power field';
                      vm.isMessageDialogOpen = true;
                      return false;
                  }
                }
              } else {
                const tempValue = scale.getValueForPixel(event.offsetY);
                valueY = tempValue < 0 ? 0 : tempValue > 100 ? 100 : tempValue;
              }
            }
            if (index >= 0){
              const current_data = cloneDeep(vm.datacollection.datasets[0].data);
              vm.onClickDimming = Math.round(valueY);
              current_data[index] = vm.onClickDimming;
              vm.currentFluctuations = vm.checkNumOfCurves(current_data);
              const previous_fluctuations = vm.dragPrevFluctuations > -1 ? vm.dragPrevFluctuations : vm.checkNumOfCurves(vm.datacollection.datasets[0].data);
              if (current_data[index] > 0 && vm.currentFluctuations > 8 && previous_fluctuations < vm.currentFluctuations){
                vm.message = 'You have reached the maximum number of dimming fluctuations in the curve, to add a fluctuation you must delete one';
                vm.isMessageDialogOpen = true;
                return false;
              }else vm.datacollection.datasets[0].data[index] = current_data[index];
            }
            this.update();
          }
        }
      };
    },
    mounted() {
      this.chartIntialization();
      this.$nextTick(() => {
        if (this.preview && this.$refs.barChart) {
          const image = this.$refs.barChart.$refs.canvas.toDataURL('image/png');
          this.setPreview(image);
        }
      });
    },
    methods: {
      chartIntialization(){
        const color = this.startType === 'dusk' && this.duskDawnProperty ? this.duskDawnChartColor : this.defaultChartColor;
        this.datacollection.datasets = [{
          data: [...this.originalTimezoneDatasets],
          backgroundColor: Array(48).fill(color),
          borderColor: Array(48).fill(this.borderColor),
          borderWidth: 0.2,
          categoryPercentage: 1.0,
          barPercentage: 1.0
        }];

        if (this.startType === 'dusk' && this.duskDawnProperty){
          this.addDuskDawnWindow();
        }
        this.$refs.barChart.renderChart(this.datacollection, this.options);
      },
      onStartTypeChanged() {
        if (this.duskDawnProperty && this.startType === 'dusk'){
          this.fillDuskDawnChart();
          this.addDuskDawnWindow();
        }else this.fillChartWithOriginalPowers(0, 48, this.defaultChartColor, this.originalTimezoneDatasets, false);
        this.$refs.barChart.renderChart(this.datacollection, this.options);
      },
      addDuskDawnWindow(){
        this.fillChartWithOriginalPowers(this.startDuskOffset, this.endDuskOffset, this.duskDawnWindowColor, [this.duskPowerLevel], true);
        this.fillChartWithOriginalPowers(this.startDawnOffset, this.endDawnOffset, this.duskDawnWindowColor, [this.dawnPowerLevel], true);
      },
      fillChartWithOriginalPowers(start, end, color, powers, singleValue){
        if (start > end){
          for (let i = start; i < 48; i++) {
            this.datacollection.datasets[0].data[i] = singleValue ? powers[0] : powers[i];
            this.datacollection.datasets[0].backgroundColor[i] = color;
          }
          for (let i = 0; i < end; i++) {
            this.datacollection.datasets[0].data[i] = singleValue ? powers[0] : powers[i];
            this.datacollection.datasets[0].backgroundColor[i] = color;
          }
        }else{
          for (let i = start; i < end; i++) {
            this.datacollection.datasets[0].data[i] = singleValue ? powers[0] : powers[i];
            this.datacollection.datasets[0].backgroundColor[i] = color;
          }
        }
      },
      fillChartWithRoundedPowers(start, end, color, powers){
        if (start > end){
          for (let i = start; i < 48; i++) {
            this.datacollection.datasets[0].data[i] = powers[i] === 0 ? 100 : powers[i];
            this.datacollection.datasets[0].backgroundColor[i] = color;
          }
          for (let i = 0; i < end; i++) {
            this.datacollection.datasets[0].data[i] = powers[i] === 0 ? 100 : powers[i];
            this.datacollection.datasets[0].backgroundColor[i] = color;
          }
        }else{
          for (let i = start; i < end; i++) {
            this.datacollection.datasets[0].data[i] = powers[i] === 0 ? 100 : powers[i];
            this.datacollection.datasets[0].backgroundColor[i] = color;
          }
        }
      },
      fillDuskDawnChart(){
        this.fillChartWithRoundedPowers(this.endDuskOffset, this.startDawnOffset, this.duskDawnChartColor, this.originalTimezoneDatasets);
        this.fillChartWithOriginalPowers(this.endDawnOffset, this.startDuskOffset, this.duskDawnChartColor, this.originalTimezoneDatasets, false);
      },
      UpdateMinMaxDuskIndex(){
        this.fillDuskDawnChart();
        this.fillChartWithOriginalPowers(this.startDuskOffset, this.endDuskOffset, this.duskDawnWindowColor, [this.duskPowerLevel], true);
        this.$refs.barChart.renderChart(this.datacollection, this.options);
      },
      UpdateMinMaxDawnIndex(){
        this.fillDuskDawnChart();
        this.fillChartWithOriginalPowers(this.startDawnOffset, this.endDawnOffset, this.duskDawnWindowColor, [this.dawnPowerLevel], true);
        this.$refs.barChart.renderChart(this.datacollection, this.options);
      },
      getDatasets(){
        return this.datacollection.datasets[0].data;
      },
      showDuskDawnWarning(){
        this.$notify({
          type: 'warning',
          title: 'Warning!',
          text: 'There is no dusk dawn property defined for this project. Dusk Dawn will not work.'
        });
      },
      checkNumOfCurves(dataset_data){
        const curves = [];
        curves.push(dataset_data[0]);
        dataset_data.forEach((dimming_level) => {
          if (dimming_level !== curves[0]) curves.unshift(dimming_level);
        });
        if (curves.length === 1 && curves[0] === 0) return 1;
        return curves.filter((dimming_level) => dimming_level !== 0).length;
      }
    },
    watch: {
      startType() {
        if ((this.startType === 'dusk' && this.endType !== 'dawn') || (this.startType === 'none' && this.endType !== 'none')){          
          this.onStartTypeChanged();
          const endType = this.startType === 'dusk' ? 'dawn' : 'none';
          this.$emit('onStartTypeChanged', endType);
        }
        if (!this.duskDawnProperty && this.startType === 'dusk' && this.endType !== 'dawn') this.showDuskDawnWarning();
      },
      endType() {
        if ((this.endType === 'dawn' && this.startType !== 'dusk') || (this.endType === 'none' && this.startType !== 'none')){
          const startType = this.endType === 'dawn' ? 'dusk' : 'none';
          this.$emit('onEndTypeChanged', startType);
          this.$nextTick(() => this.onStartTypeChanged());
        }
        if (!this.duskDawnProperty && this.endType === 'dawn' && this.startType !== 'dusk') this.showDuskDawnWarning();
      },
      timezone(){
        this.onStartTypeChanged();
      },
      duskPowerLevel(){
        if (this.duskDawnProperty && this.startType === 'dusk'){
          this.fillChartWithOriginalPowers(this.startDuskOffset, this.endDuskOffset, this.duskDawnWindowColor, [this.duskPowerLevel], true);
          this.$refs.barChart.renderChart(this.datacollection, this.options);
        }
      },
      dawnPowerLevel(){
        if (this.duskDawnProperty && this.startType === 'dusk'){
          this.fillChartWithOriginalPowers(this.startDawnOffset, this.endDawnOffset, this.duskDawnWindowColor, [this.dawnPowerLevel], true);
          this.$refs.barChart.renderChart(this.datacollection, this.options);
        }
      },
      endDuskOffset(){
        if (this.duskDawnProperty && this.startType === 'dusk') this.UpdateMinMaxDuskIndex();
      },
      endDawnOffset(){
        if (this.duskDawnProperty && this.startType === 'dusk') this.UpdateMinMaxDawnIndex();
      }
    }
  };

</script>

<style>
  .small {
    max-width: 1000px;
    margin: 0 auto;
  }

  #bar-chart:hover {
    cursor: pointer;
  }
  .dialog-class {
    border-radius: 20px;
  }
</style>
